import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface WalletState {
  address: string
}

const initialState: WalletState = {
  address: '',
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload
    },
  },
})

export const { setWalletAddress } = walletSlice.actions
export default walletSlice.reducer
