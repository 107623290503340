import React, { lazy, Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary'

const GlobalHome = lazy(() => import('./pages/GlobalHome'))
const MyNFTs = lazy(() => import('./pages/MyNFTs'))
const NotFound = lazy(() => import('./pages/NotFound'))
const Checkout = lazy(() => import('./pages/Checkout'))
const Success = lazy(() => import('./pages/Success'))
const Project = lazy(() => import('./pages/Project'))
const LoginPage = lazy(() => import('./pages/Login'))
const Collection = lazy(() => import('./pages/Collection'))
const Design = lazy(() => import('./pages/Design'))
const MainLayout = lazy(() => import('./UI/MainLayout'))
const WatchMovie = lazy(() => import('./pages/WatchMovie'))
const BannedPage = lazy(() => import('./pages/BannedPage'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const RefundPolicy = lazy(() => import('./pages/RefundPolicy'))
const Terms = lazy(() => import('./pages/Terms'))
const Community = lazy(() => import('./pages/Community'))

import LoadingComponent from './components/LoadingComponent'

interface RouteType {
  path: string
  element: React.FC
}

const router: RouteType[] = [
  {
    path: '/',
    element: GlobalHome,
  },
  {
    path: '/project/:id',
    element: Project,
  },
  {
    path: '/collection/:id',
    element: Collection,
  },
  {
    path: '/design/:id',
    element: Design,
  },
  {
    path: '/my-nfts',
    element: MyNFTs,
  },
  {
    path: '/watch-movie',
    element: WatchMovie,
  },
  {
    path: '/checkout',
    element: Checkout,
  },
  {
    path: '/success/:id',
    element: Success,
  },
  {
    path: '/login',
    element: LoginPage,
  },
  {
    path: '/community',
    element: Community,
  },
  {
    path: '/banned',
    element: BannedPage,
  },
  {
    path: '/privacy-policy',
    element: PrivacyPolicy,
  },
  {
    path: '/refund-policy',
    element: RefundPolicy,
  },
  {
    path: '/terms',
    element: Terms,
  },
  {
    path: '*',
    element: NotFound,
  },
]

// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//   alert('This site is not optimized for mobile. Please use a desktop browser.')
// }

const App: React.FC = () => {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <ErrorBoundary
          fallback={
            <NotFound
              code={500}
              message="Internal Server Error"
              description="This shoudn't have happened. Don't worry we will look into it soon."
            />
          }
        >
          <Suspense
            fallback={
              <div className="flex justify-center items-center">
                <LoadingComponent showText={true} text="Getting ready.." />
              </div>
            }
          >
            <MainLayout>
              <Routes>
                {router.map((route, index) => (
                  <Route key={index} path={route.path} element={<route.element />} />
                ))}
              </Routes>
            </MainLayout>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  )
}

export default App

