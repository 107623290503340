import { createSlice } from '@reduxjs/toolkit'

interface AlertState {
  message: string
  type: 'success' | 'error' | 'info' | 'warning'
}

const initialState: AlertState = {
  message: '',
  type: 'success',
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.message = action.payload.message
      state.type = action.payload.type
    },
  },
})

export const { setAlert } = alertsSlice.actions
export default alertsSlice.reducer
