import React from 'react'

const LoadingComponent: React.FC<{
  showText?: boolean
  text?: string
}> = ({ showText, text }) => {
  return (
    <div className="flex items-center justify-center mt-2">
      <div className="text-center">
        <svg className="animate-spin h-8 w-8 mx-auto text-gray-500" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0012 20c4.411 0 8-3.589 8-8h-4a4 4 0 11-7.917-1.248L12 10.083l1.917 4.669A4 4 0 1116 12h4c0 4.411-3.589 8-8 8a7.96 7.96 0 01-5.657-2.343z"
          />
        </svg>
        {showText && <p className="mt-2 text-sm text-gray-500">{text ? text : 'Loading...'}</p>}
      </div>
    </div>
  )
}

export default LoadingComponent
