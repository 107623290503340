import { configureChains, createConfig, Chain, createStorage } from 'wagmi'
import { mainnet, polygon, polygonMumbai } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { arcanaConnector } from './arcanaConnectors'

const APP_NAME = 'Metascreen'
// created with developer wallet at walletconnect
const WALLET_CONNECT_PROJECT_ID = 'd7fd730ea8fc085cc7a34b97c6a38657'

const getHttpRpcUrl = (chain: Chain) => {
  switch (chain.id) {
    case 137:
      return `https://polygon-bor.publicnode.com`
    case 80001:
      return `https://polygon-mumbai.blockpi.network/v1/rpc/public`
    default:
      return `https://polygon-bor.publicnode.com`
  }
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, polygon, polygonMumbai],
  [
    jsonRpcProvider({
      rpc: (chain: Chain) => ({
        http: getHttpRpcUrl(chain),
      }),
    }),
    publicProvider(),
  ],
)

const client = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: APP_NAME,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: WALLET_CONNECT_PROJECT_ID,
      },
    }),
    arcanaConnector(chains),
  ],
  publicClient,
  webSocketPublicClient,
  storage: createStorage({ storage: window.localStorage }),
})

export { chains, client }
