import { createSlice } from '@reduxjs/toolkit'

interface currentDesignDetailsInterface {
  department_id: number
  smart_contract_address: string
  token_id: number
}

const initialState: currentDesignDetailsInterface = {
  department_id: 0,
  smart_contract_address: '',
  token_id: -1,
}

const currentDesignDetails = createSlice({
  name: 'currentDesignDetails',
  initialState,
  reducers: {
    setCurrentDesignDetails: (state, action) => {
      console.log('payload', action.payload)
      if (action.payload.department_id) {
        console.log(action.payload.department_id)
        state.department_id = action.payload.department_id
      }
      if (action.payload.smart_contract_address) {
        console.log(action.payload.smart_contract_address)
        state.smart_contract_address = action.payload.smart_contract_address
      }
      if (action.payload.token_id) {
        console.log(action.payload.token_id)
        state.token_id = action.payload.token_id
      }
      localStorage.setItem('currentPurchase', JSON.stringify(action.payload))
    },
  },
})

export const { setCurrentDesignDetails } = currentDesignDetails.actions

export default currentDesignDetails.reducer

