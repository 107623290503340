import { createSlice } from '@reduxjs/toolkit'

interface BookInfo {
  email: string
}

const initialState: BookInfo = {
  email: '',
}

const bookSlice = createSlice({
  name: 'bookinfo',
  initialState,
  reducers: {
    setBookInfo: (state, action) => {
      state.email = action.payload.email
    },
  },
})

export const { setBookInfo } = bookSlice.actions

export default bookSlice.reducer

