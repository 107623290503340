import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface EmailVerifyState {
  email: string
  message: string
}

const initialState: EmailVerifyState = {
  email: '',
  message: '',
}

const emailVerifySlice = createSlice({
  name: 'emailVerify',
  initialState,
  reducers: {
    setEmailAndMessage: (state, action: PayloadAction<{ email: string; message: string }>) => {
      state.email = action.payload.email
      state.message = action.payload.message
    },
  },
})

export const { setEmailAndMessage } = emailVerifySlice.actions
export default emailVerifySlice.reducer
