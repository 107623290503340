import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { apiSlice } from './apiSlice'
import loadingReducer from '../features/loadingSlice'
import alertsReducer from '../features/alertsSlice'
import bookInfoReducer from '../features/bookSlice'
import emailverifySlice from '../features/emailverifySlice'
import walletSlice from '../features/walletSlice'
import currentDesignDetails from '../features/currentDesignSlice'

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    alerts: alertsReducer,
    bookinfo: bookInfoReducer,
    emailverify: emailverifySlice,
    wallet: walletSlice,
    currentDesignDetails: currentDesignDetails,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
