import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { client } from './config/wagmiConfig'
// @ts-ignore
import { WagmiConfig } from 'wagmi'
import './index.css'
import ReactGA from 'react-ga4'
const code = import.meta.env.VITE_REACT_GA4

ReactGA.initialize(String(code))
ReactGA.send({ hitType: 'pageview', page: window.location.pathname })

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <WagmiConfig config={client}>
      <App />
    </WagmiConfig>
  </Provider>,
)

