import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const BACKEND_URL = import.meta.env.VITE_BACKEND_URL
const API_URL = BACKEND_URL + '/metascreen/'

// initialize an empty api service that we'll inject endpoints into later as needed
const apikey = import.meta.env.VITE_API_KEY || 'abcd'
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      // Include the API key in the Authorization header
      headers.set('apikey', `${apikey}`)
      return headers
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['test', 'mynfts'],
})

