import { ArcanaConnector } from '@arcana/auth-wagmi'
import { AuthProvider } from '@arcana/auth'
import { Chain } from 'wagmi'

const ARCANA_CLIENT_ID = import.meta.env.VITE_ARCANA_ID as string

const auth = new AuthProvider(`${ARCANA_CLIENT_ID}`)
export const arcanaConnector = (chains: Chain[]) => {
  return new ArcanaConnector({
    chains,
    options: {
      auth: auth,
    },
  })
}

export { ArcanaConnector, auth }
